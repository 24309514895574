import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import usePricingExperimentFlag from '../../hooks/usePricingExperimentFlag';
import useTrueprofileEnabled from '../../hooks/useTrueprofileEnabled';
import TLink from '../../layout/TLink';
import AppContext from '../../store/AppContext';
import { staticPage } from '../../utils/util';
import KoiButton from '../koi/KoiButton';

import AccountMenu from './AccountMenu';
import TrudyLogo from './TrudyLogo';

import './DesktopHeader.scss';

export default function DesktopHeader({ children, superUser }) {
  const PRICING_EXPERIMENT_ENABLED = usePricingExperimentFlag();
  const [state] = useContext(AppContext);
  const { currentUser } = state;
  const location = useLocation();

  const isPublicProfilePage = location.pathname.includes('/u/');
  const isResumeBuilderPage = location.pathname.includes('/resume-builder');
  const isProfileBuilderPage = location.pathname.includes('/profile-builder');
  const isFTUEPreviewPage = location.pathname.includes('/view-resume');
  const trueprofileEnabled = useTrueprofileEnabled();

  const renderPublicMenu = () => {
    const hideLinks = isFTUEPreviewPage && PRICING_EXPERIMENT_ENABLED;
    if (isPublicProfilePage && trueprofileEnabled) {
      return (
        <>
          <a
            className="nav-item"
            href={staticPage('/truprofile')}
            target="_blank"
            rel="noreferrer"
          >
            TruProfile
          </a>
          <a className="nav-item" href="/login">
            Log In
          </a>
          {!isPublicProfilePage && (
            <KoiButton
              className="cta-button"
              variant="primary"
              as={Link}
              to="/profile-builder"
            >
              Build your FREE TruProfile
            </KoiButton>
          )}
        </>
      );
    }

    if (isResumeBuilderPage || isProfileBuilderPage) {
      return (
        <>
          <a
            className="nav-item"
            href={staticPage('/resume')}
            target="_blank"
            rel="noreferrer"
          >
            Resume
          </a>
          <a
            className="nav-item"
            href={staticPage('/pricing')}
            target="_blank"
            rel="noreferrer"
          >
            Pricing
          </a>
        </>
      );
    }

    return (
      <>
        {trueprofileEnabled && !hideLinks && (
          <a
            className="nav-item"
            href={staticPage('/truprofile')}
            target="_blank"
            rel="noreferrer"
          >
            TruProfile <span className="new-badge">NEW</span>
          </a>
        )}

        {!hideLinks && (
          <>
            <a
              className="nav-item"
              href={staticPage('/resume')}
              target="_blank"
              rel="noreferrer"
            >
              Resume
            </a>
            <a
              className="nav-item"
              href={staticPage('/pricing')}
              target="_blank"
              rel="noreferrer"
            >
              Pricing
            </a>
            <a className="nav-item" href={'/login'}>
              Log In
            </a>
            <KoiButton
              className="cta-button"
              variant="primary"
              as={Link}
              to={'/resume-builder'}
            >
              Try it now
            </KoiButton>
          </>
        )}
      </>
    );
  };

  const renderPrivateMenu = () => {
    if (superUser) {
      return (
        <TLink
          className="nav-item"
          to="/logout"
          properties={{ widget_name: 'Pricing' }}
        >
          Log out
        </TLink>
      );
    }
    return (
      <>
        <TLink
          className="nav-item"
          data-cy="pricing-link"
          to="/plans"
          properties={{ widget_name: 'Pricing' }}
        >
          Pricing
        </TLink>

        {currentUser.onboarding_step === 'dashboard' && (
          <TLink
            className="nav-item"
            data-cy="dashboard-link"
            to="/dashboard?tab=documents"
            properties={{ widget_name: 'Dashboard' }}
          >
            My dashboard
          </TLink>
        )}

        <AccountMenu />
      </>
    );
  };

  return (
    <div className="__desktop-header">
      <div className="flex">
        <TrudyLogo />
        {superUser && <p className="admin-label">ADMIN</p>}
      </div>

      {children}

      <div className="menu-links">
        {!currentUser?.registered ? renderPublicMenu() : renderPrivateMenu()}
      </div>
    </div>
  );
}
