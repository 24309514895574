import { forwardRef } from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';

import './TruepathInput.scss';

const TruepathInput = forwardRef(function TruepathInput(
  {
    as = 'input',
    async = false,
    className,
    containerClassName,
    children,
    errorMessage,
    hideLabel,
    hideRequiredMark = false,
    isInvalid,
    label,
    required,
    type = 'text',
    value,
    hasCustomClass = false,
    ...rest
  },
  ref,
) {
  const SpecialTypes = {
    select: Form.Select,
    checkbox: Form.Check,
    autocomplete: async ? AsyncTypeahead : Typeahead,
  };

  const Element = SpecialTypes[type] || Form.Control;

  const elementClass = classNames(
    as === 'textarea' && 'textarea-input',
    isInvalid && 'form-error is-invalid',
    className,
  );

  return (
    <div
      className={classNames(
        '__truepath-input',
        {
          customTruepathInputStyle: hasCustomClass,
        },
        containerClassName,
      )}
      data-cy="truepath-input"
    >
      {type !== 'checkbox' && !hideLabel && (
        <Form.Label className="truepath-input-label">
          {label}
          {required && !hideRequiredMark && (
            <span className="required-mark">*</span>
          )}
        </Form.Label>
      )}

      <Element
        as={as}
        className={elementClass}
        label={label}
        ref={ref}
        required={required}
        selected={value}
        type={type}
        value={value || ''}
        {...rest}
      >
        {children}
      </Element>
      <Form.Control.Feedback type="invalid">
        {errorMessage}
      </Form.Control.Feedback>
    </div>
  );
});

export default TruepathInput;
