import React, { useState } from 'react';

import SplashScreenImg from '../../assets/fakeDoor/splash-screen-img.png';
import SplashScreenImgMobile from '../../assets/fakeDoor/splash-screen-img-mobile.png';
import { ReactComponent as CreationIcon } from '../../assets/icons/koi/creation.svg';
import { ReactComponent as EmbedIcon } from '../../assets/icons/koi/embed.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/koi/share-green.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/koi/user-green.svg';
import { useWindowSize } from '../../hooks/useWindowSize';
import Tracking from '../../utils/tracking';

import FakeDoorModal from './FakeDoorModal';

import './SplashScreen.scss';

const SplashScreen = ({ onCloseSplashScreen }) => {
  const [openModal, setOpenModal] = useState(false);
  const { isMobile, isTablet } = useWindowSize();

  return (
    <div className="__splash-screen">
      <div className="screen">
        <div className="spash-container">
          <div className="content">
            <h1 className="large-title">
              <span
                onclick="handleShowSplashScreen(false)"
                onClick={onCloseSplashScreen}
                className="back-link"
              >
                ← Back
              </span>
              <br />
              <span className="__highlight">
                <div className="__highlight-color"></div>TruProfile:
              </span>{' '}
              Your professional <br />
              brand, reimagined
            </h1>

            <p className="subtitle">
              In today's job market, it's not just about your skills—it's about
              who you are. TruProfile transforms your professional identity by
              highlighting how your unique personality drives impact in your
              career, revealing the person behind the experience.
            </p>
            <button
              className="cta-button"
              onClick={() => {
                setOpenModal(true);
                Tracking.trackUserClicked({
                  widget_name: 'trueprofile-splashscreen-modal-open',
                });
              }}
            >
              <p className="cta-button-text">Build your FREE TruProfile</p>
            </button>
            <div className="features">
              <p className="features-title">
                Here’s how TruProfile will help you stand out:
              </p>
              <div>
                <li>
                  <div className="icon-wrapper">
                    <UserIcon className="feature-icons" />
                  </div>
                  <div className="wrapper">
                    <span className="strong-text">Personality showcase:</span>
                    <span className="text">
                      Displays your distinct, scientifically validated
                      personality strengths in the professional world.
                    </span>
                  </div>
                </li>
                <li>
                  <div className="icon-wrapper">
                    <EmbedIcon className="feature-icons" />
                  </div>
                  <div className="wrapper">
                    <span className="strong-text">Online integration:</span>
                    <span className="text">
                      Seamlessly complements your LinkedIn™ and other existing
                      profiles.
                    </span>
                  </div>
                </li>
                <li>
                  <div className="icon-wrapper">
                    <CreationIcon className="feature-icons" />
                  </div>

                  <div className="wrapper">
                    <span className="strong-text">Easy creation:</span>
                    <span className="text">
                      Quickly craft your professional story with our guidance.
                    </span>
                  </div>
                </li>
                <li>
                  <div className="icon-wrapper">
                    <ShareIcon className="feature-icons" />
                  </div>

                  <div className="wrapper">
                    <span className="strong-text">Shareability:</span>
                    <span className="text">
                      Effortlessly share with hiring managers and expand your
                      network.
                    </span>
                  </div>
                </li>
              </div>
            </div>
          </div>
          <div className="profile-preview">
            <img
              id="splash-screen-img"
              src={
                isMobile || isTablet ? SplashScreenImgMobile : SplashScreenImg
              }
              alt="Profile Preview"
            />
          </div>
        </div>
      </div>
      <div>
        <FakeDoorModal
          show={openModal}
          onHideParent={() => onCloseSplashScreen('AVOID_LOG')}
          onHide={(event) => {
            setOpenModal(false);
            if (event !== 'AVOID_LOG') {
              Tracking.trackUserClicked({
                widget_name: 'trueprofile-splashscreen-modal-close',
              });
            }
          }}
        />
      </div>
    </div>
  );
};

export default SplashScreen;
