import './TrudyLogo.scss';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '../../assets/icons/koi/logo.svg';
import { useAppContext } from '../../store/AppContext';

export default function TrudyLogo() {
  const { appState } = useAppContext();
  const { currentUser } = appState;
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (currentUser) {
      navigate('/dashboard');
    }
  };

  return <Logo onClick={handleLogoClick} className="__trudy-logo" />;
}
