import { range } from 'lodash';
import { DateTime } from 'luxon';

export function addDaysToDate(days, date, format = 'DD') {
  const currentDate = date || DateTime.now();
  const resultDate = currentDate?.plus({ days });
  return resultDate?.toFormat(format);
}

export function formatDateToYYMMDD(date) {
  const formattedDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');

  return formattedDate;
}

export function formatISODateToMMYY(isoString) {
  const formattedDate = DateTime.fromISO(isoString).toFormat('LLLL yyyy');

  return formattedDate;
}

export function formatDate(date, format = 'MMM dd, yyyy') {
  return DateTime.fromISO(date).toFormat(format);
}

export function formatCustomDate(
  date,
  fromFormat = 'MM/yyyy',
  toFormat = 'MMM yyyy',
) {
  if (!date) {
    return null;
  }
  return DateTime.fromFormat(date, fromFormat).toFormat(toFormat);
}

export function relativeOrFormattedDate(inputDate, format) {
  const date = DateTime.fromISO(inputDate);
  const now = DateTime.now();

  if (date.hasSame(now, 'day')) {
    return 'today';
  } else if (date.plus({ days: 1 }).hasSame(now, 'day')) {
    return 'yesterday';
  }

  return date.toFormat(format);
}

export function getBeginningOfMonth() {
  // Get the current date and set it to the beginning of the month
  const beginningOfMonth = DateTime.now().startOf('month');

  return beginningOfMonth.toFormat('yyyy-MM-dd');
}

export function sortByDate(a, b, dateKey, sortDirection = 'desc') {
  const date1 = a[dateKey]
    ? DateTime.fromFormat(a[dateKey], 'yyyy-MM-dd')
    : null;
  const date2 = b[dateKey]
    ? DateTime.fromFormat(b[dateKey], 'yyyy-MM-dd')
    : null;

  if (!date1 || !date2) return 0;

  const comparison = date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
  return sortDirection === 'desc' ? -comparison : comparison;
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const YEARS = range(new Date().getFullYear(), new Date().getFullYear() - 31);

export const renderMonthOptions = () => {
  const options = MONTHS.map((month, index) => (
    <option key={month} value={index + 1}>
      {month}
    </option>
  ));
  options.unshift(
    <option key="select month" value="">
      Month
    </option>,
  );
  return options;
};

export const renderYearOptions = () => {
  const options = YEARS.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
  options.unshift(
    <option key="select year" value="">
      Year
    </option>,
  );
  return options;
};

export const renderDateField = (start_date, end_date, current) => {
  const startDate = start_date || 'Start date';
  const endDate = end_date || (current ? 'Current' : 'End date');
  return `${startDate} - ${endDate}`;
};

export const searchWithinPeriod = (startDate, endDate, list) => {
  const fromDate = DateTime.fromISO(startDate);
  const toDate = DateTime.fromISO(endDate);

  const filteredList = list.filter((item) => {
    const createdAt = DateTime.fromISO(item.created_at);
    return createdAt >= fromDate && createdAt <= toDate;
  });

  return filteredList;
};
